import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import api from "centralApi";

export const getAssessmentsByExpertID = async (id) => {
  const assessmentArr = []
  const assessmentRef = collection(db, "AssessmentForms")
  const assessmentQuery = query(assessmentRef, where('expertId', '==', id))
  const assessmentSnap = await getDocs(assessmentQuery)

  if (!assessmentSnap.empty) {
    assessmentSnap.forEach(doc => {
      assessmentArr.push({ id: doc.id, ...doc.data() })
    })
  }

  return assessmentArr
}

export const onRefund = async (paymentId, userID, userName, expert) => {
  if(confirm('Please Confirm your Request refund action')) {
    const emails = []
    const {data: admins} = await api.get('getAdmins')
    admins.forEach(data => {
      if (data?.emails_permissions?.length) {
        const keys = data.emails_permissions.map(i => i?.key)
        if (keys.includes('new_request_refund')) {
          emails.push(data.email)
        }
      }
    })

    let price = 0
    let currency = ''
    if (!paymentId || paymentId.split('/').length < 2) paymentId = '-/-';
    const paymentType = paymentId.split('/')[0]
    const paymentID = paymentId.split('/')[1]

    await getDoc(doc(db, paymentType, paymentID)).then(doc => {
      if (doc.exists()) {
        price = paymentType === 'Stripe' ? doc.data().data.object.amount_total / 100 : doc.data().total_price
        currency = paymentType === 'Stripe' ? doc.data().data.object.currency : doc.data().currency
      }
    })

    let body = ''
    let subject = ''
    const metaRef = doc(db, 'Meta', 'notifications_txt')
    await getDoc(metaRef).then(metaDoc => {
      if (metaDoc.exists()) {
        body = metaDoc.data().email_new_request_refund_body
          ?.replace('${expertID}', expert.id)
          ?.replace('${expert_name}', expert.name)
          ?.replace('${userID}', userID)
          ?.replace('${user_name}', userName)
          ?.replace('`+total_amount+`', `${price} ${currency.toUpperCase()}`)
        subject = metaDoc.data().email_new_request_refund_subtitle
      }
    })

    const emailsRef = doc(collection(db, 'Emails'))
    await setDoc(emailsRef, {
      body,
      id: emailsRef.id,
      recipients: emails.join(', '),
      send: serverTimestamp(),
      sent: false,
      subject
    })
  }
}