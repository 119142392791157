import React, { useEffect, useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { useAsyncDebounce } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { SearchIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPayments } from "helpers";
import api from "centralApi";
import { setPayments } from "store/slices/paymentsSlice";
import { setAllDiscounts } from "store/slices/discountsSlice";
import PaymentTable from "./PaymentTable";

const Payments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const payments = useSelector((state) => state.payments.list);
  const discount = useSelector((state) => state.discounts.discount);

  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const onChange = useAsyncDebounce((value) => {
    setSearchValue(value || undefined);
  }, 200);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const { data: historyArr } = await api.get("paymentsByExpertID", {
        params: { expertId: user.id, status: "complete" },
      });
      setHistoryData(historyArr || []);

      const paymentsData = await getPayments(user.id);
      if (paymentsData) {
        const {
          payments: paymentsArr,
          discount,
          rbDiscount,
          qcADiscount,
          qcBDiscount,
          userCurrency,
        } = paymentsData;
        dispatch(setPayments(paymentsArr));
        dispatch(
          setAllDiscounts({
            discount,
            rbDiscount,
            qcADiscount,
            qcBDiscount,
            userCurrency,
          })
        );
      }
    } catch (err) {
      console.log("err", err);
      setIsError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchData();
    }
  }, [user.id]);

  return (
    <Flex direction="column">
      <h2 className="title">{t("portal_payments_top_title")}</h2>
      <p className="descr">{t("portal_payments_top_description")}</p>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && !payments.length && historyData.length ? (
        <Flex justifyContent="flex-end" mt="20px">
          <span className="custom-link">
            <Link to="/admin/payments/history">
              {" "}
              {t("portal_payments_link")}
            </Link>
          </span>
        </Flex>
      ) : null}
      {!isLoading && isError && <p className="error">{isError}</p>}
      {!isLoading && !isError ? (
        payments?.length ? (
          <>
            <Flex
              alignItems={{ md: "center", base: "flex-start" }}
              justifyContent="space-between"
              flexDirection={{
                base: "column",
                md: "row",
              }}
              mb={{ md: 0, base: "20px" }}
            >
              <label className="custom-input-wrapper">
                <SearchIcon color={"#3D2541"} w="18px" h="18px" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onChange(e.target.value);
                  }}
                  placeholder={t("portal_payments_table_search_placeholder")}
                  className={"custom-input"}
                />
              </label>
              {historyData.length ? (
                <span className="custom-link">
                  <Link to="/admin/payments/history">
                    {" "}
                    {t("portal_payments_link")}
                  </Link>
                </span>
              ) : null}
            </Flex>

            <h3 className="title payments-title">GBP Payments</h3>
            <PaymentTable
              currency={"gbp"}
              initPayments={payments}
              search={searchValue}
              discount={discount}
            />
            <br />
            <hr className="hr" />

            <h3 className="title payments-title">Euro Payments</h3>
            <PaymentTable
              currency={"eur"}
              initPayments={payments}
              search={searchValue}
              discount={discount}
            />
            <br />
          </>
        ) : (
          <p className="error">{t("portal_payments_empty_table")}</p>
        )
      ) : null}
    </Flex>
  );
};

export default Payments;
