import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  Center,
} from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import LibiLogo from "assets/img/libiLogoBlack.png";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactSignatureCanvas from "react-signature-canvas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { EditIcon } from "components/Icons/Icons";
import UkModulusChecking from 'uk-modulus-checking'
import api from "centralApi";
// import countries from "variables/countries";

const requiredStr = yup.string().required("This field is required")

const schema = yup.object({
  companyName: requiredStr,
  address: requiredStr,
  city: requiredStr,
  country: requiredStr,
  postcode: requiredStr,
  contactTitle: requiredStr,
  contactFirstName: requiredStr,
  contactLastName: requiredStr,
  contactEmail: yup
    .string()
    .email("Invalid E-mail")
    .required("This field is required")
});

export default function SignAgreement() {
  const { push } = useHistory();
  const [template, setTemplate] = useState(null);
  const [data, setData] = useState(null);

  const [countries, setCountries] = useState([])

  // const agreementSign = async () => {
  //     const auth = getAuth();
  //     const user = auth.currentUser;
  //     const userRef = doc(db, "Users", user.uid);
  //     await updateDoc(userRef, {
  //         agreementSigned: true,
  //     });
  //     location.href = "/auth/signup";
  // };

  const [htmlFull, setHtmlFull] = useState("");
  const [libiSign, setLibiSign] = useState("");
  const [bankError, setBankError] = useState("");

  useEffect(() => {
    const docRef = doc(db, "Meta", "ExpertAgreement");
    getDoc(docRef).then((snap) => {
      if (snap.exists()) {
        let data = snap.data();
        setHtmlFull(data.htmlFull);
        setData({ date: moment().format("dddd, MMMM Do YYYY") });
        setTemplate(data.html);
        setLibiSign(data.libiSng);
      }
    });
  }, []);

  const auth = getAuth();
  const user = auth.currentUser;
  const [partnerFirstName, setPartnerFirstName] = useState("");
  const [partnerLastName, setPartnerLastName] = useState("");

  useEffect(() => {
    api.get('userByID', {params: {userId: user.uid}}).then(res => {
      setPartnerFirstName(res.data.firstName);
      setPartnerLastName(res.data.lastName);
    })

    api.get('getCountries').then(res => setCountries(res.data))
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setError
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [canvasSignature, setCanvasSignature] = useState(null);
  const [canvasError, setCanvasError] = useState("");
  const [clearCanvas, setClearCanvas] = useState(null);
  const [begin, setBegin] = useState(false);
  const [contactPhone, setContactPhone] = useState('')
  const [isIban, setIsIban] = useState(true)

  const clearSignature = () => {
    clearCanvas.clear();
  };

  const onSubmit = async () => {
    const bankIsValid = new UkModulusChecking({
        accountNumber: getValues("bankAccountNumber"),
        sortCode: getValues("sortcode"),
    }).isValid();

    if (!canvasSignature || canvasSignature.isEmpty()) {
      setCanvasError("Please sign the agreement");
    }
    else if (isIban && !getValues("iban")) {
      setError('iban', { type: 'required', message: 'This field is required' });
    }
    else if (!isIban && !getValues("bankAccountNumber") && !getValues("sortcode")) {
      setError('bankAccountNumber', { type: 'required', message: 'This field is required' });
      setError('sortcode', { type: 'required', message: 'This field is required' });
    }
    else if (!isIban && !bankIsValid) {
      setBankError("Bank number or sort code is invalid");
    }
    else {
      setCanvasError(null);
      setBankError("");

      const auth = getAuth();
      const user = auth.currentUser;
      const docRef = await addDoc(collection(db, "docs"), {
        vars: JSON.stringify({
          companyName: getValues("companyName"),
          address: getValues("address"),
          city: getValues("city"),
          country: getValues("country"),
          postcode: getValues("postcode"),
          contactTitle: getValues("contactTitle"),
          contactFirstName: getValues("contactFirstName"),
          contactLastName: getValues("contactLastName"),
          contactPhone: contactPhone,
          contactEmail: getValues("contactEmail"),
          ...(isIban
            ? {iban: getValues("iban")}
            : {
              bankAccountNumber: getValues("bankAccountNumber"),
              sortcode: getValues("sortcode"),
            }
          ),
          date: moment().format("dddd, MMMM Do YYYY"),
          sign: canvasSignature
            .getTrimmedCanvas()
            .toDataURL("image/png"),
          libiSign: libiSign,
        }),
        template: htmlFull,
        signedBy: user.uid,
      });

      await api.post('updateUser', {
        id: user.uid,
        companyName: getValues("companyName"),
        address: getValues("address"),
        city: getValues("city"),
        country: getValues("country"),
        postcode: getValues("postcode"),
        contactFirstName: getValues("contactFirstName"),
        contactLastName: getValues("contactLastName"),
        contactPhone: contactPhone,
        contactEmail: getValues("contactEmail"),
        ...(isIban
          ? {iban: getValues("iban")}
          : {
            bankAccountNumber: getValues("bankAccountNumber"),
            sortcode: getValues("sortcode"),
          }
        ),
        agreementSigned: true,
        agreementID: docRef.id,
      }).then(res => {
        if(res.status === 200) {
          reset()
          push("/admin/dashboard")
        }
      })
    }
  };

  return (
    <Flex flexDirection={{ lg: 'row', sm: 'column' }}>
      <Box w={{ lg: '50%', sm: '100%' }} bg="#fff" p={{ lg: '120px 60px 90px', sm: '32px' }}>
        <Center>
          <img src={LibiLogo} alt="Logo" width={135} />
        </Center>
        <h2 className="title" style={{ margin: '8px auto 24px', textAlign: 'center' }}>Product Affiliate Service Agreement</h2>
        <p style={{ textAlign: 'justify' }}>
          Thank you for your interest in Libi & Daughters. The following is an application to prescribe products from Libi & Daughters at preferential pricing and commission to your clients/patients. Once your account has been established, you can access our online portal that will display your ongoing account statement. You will be allocated a code to pass to patients, giving them preferential pricing. Products will be delivered directly to these clients/patients by Libi & Daughters. You will receive a commission on all of these products.
        </p>
        <p style={{ textAlign: 'justify', margin: '20px 0' }}>
          In order to establish an account, the application and Affiliate Terms & Conditions must both be completed.
        </p>
        <p style={{ textAlign: 'justify' }}>
          If you have any additional questions about opening an account, please feel free to call Jamie Faulkner at <a href="tel:+447470136044" className="custom-link">+44 7470 136044</a> or <a href="mailto:jamie@Libi.com" className="custom-link">jamie@Libi.com</a>.
        </p>
        <h2 className="title" style={{ margin: '47px 0 10px', textAlign: 'center' }}>Libi & Daughters Alchemy Ltd</h2>
        <Text
          fontSize={16}
          fontWeight="500"
          textAlign={"center"}
          mb="25px"
        >64 New Cavendish Square | London | W1G 8TB</Text>
        <Text
          fontFamily={'Cormorant Garamond'}
          fontWeight="600"
          fontSize={"22px"}
          mb="10px"
        >PRODUCT RESELLER SERVICE AGREEMENT</Text>
        <Text
          fontFamily={'Cormorant Garamond'}
          fontWeight="600"
          fontSize={"22px"}
          mb="10px"
        >PARTIES</Text>
        <Text
          fontFamily={'Cormorant Garamond'}
          fontWeight="600"
          fontSize={"16px"}
          mb="10px"
        >This Affiliate Service Agreement ("the Agreement") is entered into the parties set out at the foot of this Agreement on the date set out at the foot of this Agreement.</Text>
        <Text
          fontFamily={'Cormorant Garamond'}
          fontWeight="600"
          fontSize={"22px"}
          mb="20px"
        >BACKGROUND</Text>
        <ul style={{ marginLeft: '16px' }}>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >Either party can terminate this Agreement at any time, with no further obligation, by giving 30 days' written notice. On termination, Libi & Daughters shall pay to the Practitioner all commissions earned on orders placed during the lifetime of introduced customers/patients.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >The practitioner and Libi & Daughters will notify each other in writing in the event of any address change.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >Nothing in this Agreement is intended to or shall be deemed to establish any partnership or joint venture between any of the parties, constitute any party the agent of another party, or authorise any party to make or enter into any commitments for or on behalf of any other party</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >This Agreement is personal to the practitioner, and the practitioner shall not assign, transfer, charge, subcontract or deal in any way with their rights or obligations under this Agreement without Libi & Daughters' prior written consent.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >Any changes to this Agreement must be in writing and signed by both parties</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >This is the entire Agreement between both parties.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >No one other than a party to this Agreement, their successors and permitted assignees shall have any right to enforce any of its terms.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >This Agreement, and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims), shall be governed by and construed in accordance with the law of England and Wales.</li>
          <li
            style={{ marginBottom: '20px', textAlign: 'justify' }}
          >Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising from or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</li>
        </ul>
        <p style={{ textAlign: 'center', marginBottom: '10px' }}>Agreement made this date: {moment().format("dddd, MMMM Do YYYY")}</p>
        <p style={{ textAlign: 'center' }}>Between Libi & Daughters, a company incorporated in England and Wales and having its registered office at 64 New Cavendish Square, London, United Kingdom, W1G 8TP</p>
      </Box>
      <Box w={{ lg: '50%', sm: '100%' }} padding={{ lg: '120px 0 90px', sm: '32px' }}>
        <Center>
          <img src={LibiLogo} alt="Logo" width={135} />
        </Center>

        <h2 className="title" style={{ margin: '8px auto 24px', textAlign: 'center' }}>Sign our Agreement</h2>

        <form style={{ maxWidth: '440px', margin: '0 auto' }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors?.companyName} mb="20px">
            <FormLabel>Company or Partner name*</FormLabel>
            <Input
              {...register("companyName")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Company or Partner nam"
            />
            {errors?.companyName && (
              <FormErrorMessage>
                {errors.companyName?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.address} mb="20px">
            <FormLabel>Address*</FormLabel>
            <Input
              {...register("address")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Address"
            />
            {errors?.address && (
              <FormErrorMessage>
                {errors.address?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.city} mb="20px">
            <FormLabel>City*</FormLabel>
            <Input
              {...register("city")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="City"
            />
            {errors?.city && (
              <FormErrorMessage>
                {errors.city?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.country} mb="20px">
            <FormLabel>Country*</FormLabel>
            <Select {...register("country")} borderColor={'rgba(61, 37, 65, 0.4)'} h="44px">
              <option value="">Country</option>
              {countries.map(i => (
                <option key={i.code} value={i.code}>{i.name}</option>
              ))}
            </Select>
            {errors?.country && (
              <FormErrorMessage>
                {errors.country?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.postcode} mb="20px">
            <FormLabel>Postcode*</FormLabel>
            <Input
              {...register("postcode")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Postcode"
            />
            {errors?.postcode && (
              <FormErrorMessage>
                {errors.postcode?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.contactTitle} mb="20px">
            <FormLabel>Contact title*</FormLabel>
            <Select
              {...register("contactTitle")}
              placeholder="Select title"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
            >
              <option value="Mr.">Mr.</option>
              <option value="Ms.">Ms.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Dr.">Dr.</option>
            </Select>
            {errors?.contactTitle && (
              <FormErrorMessage>
                {errors.contactTitle?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={errors?.contactFirstName}
            mb="20px"
          >
            <FormLabel>Contact first name*</FormLabel>
            <Input
              {...register("contactFirstName")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Contact first name"
            />
            {errors?.contactFirstName && (
              <FormErrorMessage>
                {errors.contactFirstName?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={errors?.contactLastName}
            mb="20px"
          >
            <FormLabel>Contact last name*</FormLabel>
            <Input
              {...register("contactLastName")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Contact last name"
            />
            {errors?.contactLastName && (
              <FormErrorMessage>
                {errors.contactLastName?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.contactPhone} mb="20px">
            <FormLabel>Contact phone*</FormLabel>
            <PhoneInput
              country={'gb'}
              countryCodeEditable={false}
              value={contactPhone}
              onChange={value => setContactPhone(value)}
              inputStyle={{
                background: 'transparent',
                color: '#3D2541',
                height: '44px',
                width: '100%',
                borderRadius: '0.375rem',
                borderColor: 'rgba(61, 37, 65, 0.4)'
              }}
              dropdownClass="phone-input-dropdown"
              buttonClass="phone-input-wrapper"
            />
            {errors?.contactPhone && <FormErrorMessage>{errors.contactPhone?.message}</FormErrorMessage>}
          </FormControl>
          <FormControl
            isInvalid={errors?.contactEmail}
            mb="20px"
          >
            <FormLabel>Contact email*</FormLabel>
            <Input
              {...register("contactEmail")}
              type="text"
              borderColor={'rgba(61, 37, 65, 0.4)'}
              height="44px"
              placeholder="Contact email"
            />
            {errors?.contactEmail && (
              <FormErrorMessage>
                {errors.contactEmail?.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <h2 className="title" style={{ fontSize: '26px', margin: '10px 0 24px', textAlign: 'center' }}>For Commission Payment, Please Supply</h2>

          <Box mb="4px" textAlign={'center'}>
            <div className='af-tabs'>
              <div 
                className={`title af-tab ${!isIban && 'af-tab-active'}`}
                onClick={() => setIsIban(false)}
              >
                UK local bank account
              </div>
              <div 
                className={`title af-tab ${isIban && 'af-tab-active'}`}
                onClick={() => setIsIban(true)}
              >
                IBAN
              </div>
            </div>
          </Box>

          {isIban ?
            <FormControl isInvalid={errors?.iban} mb="20px">
              <FormLabel>IBAN*</FormLabel>
              <Input
                {...register("iban")}
                type="text"
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder="Your IBAN number"
              />
              <Text
                fontWeight={500}
                fontSize={12}
                color="#3D2541"
                mt="7px"
              >
                Example: GB88 TRWI 2314 7060 7017 99
              </Text>
              {errors?.iban && (
                <FormErrorMessage>
                  {errors.iban?.message}
                </FormErrorMessage>
              )}
            </FormControl> :
           <>
            <FormControl
              isInvalid={errors?.bankAccountNumber}
              mb="20px"
            >
              <FormLabel>Bank account number*</FormLabel>
              <Input
                {...register("bankAccountNumber")}
                type="text"
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder="Bank account number"
              />
              <Text
                fontWeight={500}
                fontSize={12}
                color="#3D2541"
                mt="7px"
              >
                Example: 60701799
              </Text>
              {errors?.bankAccountNumber && (
                <FormErrorMessage>
                  {errors.bankAccountNumber?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors?.sortcode} mb="20px">
              <FormLabel>Sortcode*</FormLabel>
              <Input
                {...register("sortcode")}
                type="text"
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder="Sortcode"
              />
              <Text
                fontWeight={500}
                fontSize={12}
                color="#3D2541"
                mt="7px"
              >
                Example: 23-14-70
              </Text>
              {errors?.sortcode && (
                <FormErrorMessage>
                  {errors.sortcode?.message}
                </FormErrorMessage>
              )}
            </FormControl>
           </>
          }

          <h2 className="title" style={{ fontSize: '26px', margin: '10px 0 24px', textAlign: 'center' }}>Libi and Daughters Alchemy Ltd</h2>

          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Name:</b>&nbsp; Dr. Inna Szalontay</Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Position:</b>&nbsp; Director</Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Date:</b>&nbsp; {moment().format("dddd, MMMM Do YYYY")}</Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Signature:</b>&nbsp; -</Text>

          <h2 className="title" style={{ fontSize: '26px', margin: '10px 0 24px', textAlign: 'center' }}>Partner</h2>

          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Name:</b>&nbsp; {`${partnerFirstName} ${partnerLastName}`}</Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Date:</b>&nbsp; {moment().format("dddd, MMMM Do YYYY")}</Text>
          <Text
            fontSize={15}
            color={'#3D2541'}
            mb={'20px'}
          ><b>Signature:</b></Text>

          <Flex
            background="#FFFFFF"
            border="1px solid rgba(61, 37, 65, 0.4)"
            borderRadius="6px"
            position="relative"
          >
            <div
              style={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                cursor: 'pointer'
              }}
              onClick={clearSignature}
            >
              <EditIcon />
            </div>
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                width: '440px',
                height: '210px'
              }}
              ref={(ref) => {
                setCanvasSignature(ref);
                setClearCanvas(ref);
              }}
              onBegin={setBegin}
            />
          </Flex>

          <Text color="gray" fontSize="sm" mt="20px">
            Please use your mouse to create a signature
          </Text>

          <Text color="red.400" fontSize="sm" mt="10px">
            {canvasError || bankError}
          </Text>

          <button
            type="submit"
            className="custom-btn"
            style={{ width: '100%', marginTop: '10px' }}
          >
            Submit
          </button>
        </form>
      </Box>
    </Flex>
  );
}
