import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Spinner, Box, useToast } from "@chakra-ui/react";
import {
  useAsyncDebounce,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import TableDropdown from "components/TableDropdown";
import { Doc } from "components/Icons/Icons";
import { EditCircle } from "components/Icons/Icons";
import { PlusCircle } from "components/Icons/Icons";
import { useTranslation } from "react-i18next";
import { CreditIcon } from "components/Icons/Icons";
import Pagination from "components/Pagination";
import { getUsers } from "helpers";
import DotsLoader from "components/DotsLoader";
import { updateStorageOrders } from "helpers";
import { onRefund } from "dbManager";
import { requestPayment } from "helpers";
import { setCustomers } from "store/slices/customersSlice";
import api from "centralApi";

const ExpertsUsers = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.customers.list);

  const toast = useToast();

  const [sentRequestIds, setSentRequestIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: t("portal_customers_table_column_1_title"),
        accessor: "created",
        Cell: ({ value }) =>
          value ? (
            <span title={moment(value).format("HH:mm MMM DD YYYY")}>
              {moment(value).format("DD/MM/YYYY")}
            </span>
          ) : (
            "-"
          ),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        sortType: (a, b) => {
          if (
            new Date(a.values.created).getTime() <
            new Date(b.values.created).getTime()
          ) {
            return -1;
          } else if (
            new Date(a.values.created).getTime() >
            new Date(b.values.created).getTime()
          ) {
            return 1;
          }

          return 0;
        },
      },
      {
        Header: t("portal_customers_table_column_2_title"),
        accessor: "name",
        Cell: (value) => {
          return (
            <Link to={`/admin/customers/${value.cell.row.original.id}`}>
              <u>{value.cell.row.original.name}</u>
            </Link>
          );
        },
      },
      {
        Header: t("portal_customers_table_column_6_title"),
        accessor: "lastPackage",
        Cell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        // sortType: (a, b) => {
        //   if (moment(a.values.lastOrder).unix() < moment(b.values.lastOrder).unix()) {
        //     return -1;
        //   } else if (moment(a.values.lastOrder).unix() > moment(b.values.lastOrder).unix()) {
        //     return 1;
        //   };

        //   return 0;
        // },
      },
      {
        Header: t("portal_customers_table_column_7_title"),
        accessor: "lStatus",
        Cell: ({ value }) =>
          (value?.charAt(0)?.toUpperCase() + value?.slice(1))?.replace(
            "_",
            " "
          ),
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
    ],
    [sentRequestIds]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageSize: 10 },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "action",
          Header: () => "Manage",
          Cell: ({ row }) => (
            <TableDropdown>
              <div
                className="dropdown-item"
                onClick={() => push(`/admin/customers/${row.original.id}`)}
              >
                <Doc /> {t("portal_customers_table_menu_1")}
              </div>
              <div
                className="dropdown-item"
                onClick={() => push(`/admin/customers/edit/${row.original.id}`)}
              >
                <EditCircle /> {t("portal_customers_table_menu_2")}
              </div>
              {row.original.actionStatus === "payment_needed" && (
                <>
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      push(`/admin/payments?oid=${row.original.lastOrderId}`)
                    }
                  >
                    <CreditIcon color="inherit" />
                    {t("portal_orders_table_menu_2")}
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      requestPayment(
                        row.original.id,
                        row.original.email,
                        sentRequestIds,
                        setSentRequestIds,
                        toast
                      )
                    }
                  >
                    <CreditIcon color="inherit" />
                    {sentRequestIds.includes(row.original.id)
                      ? t("portal_customers_table_menu_8")
                      : t("portal_customers_table_menu_4")}
                  </div>
                </>
              )}
              {row.original.actionStatus === "skip_initial" && (
                <div
                  className="dropdown-item"
                  onClick={handleSetWaitingDiagnoseStatus(row)}
                >
                  <CreditIcon color="inherit" />
                  {t("portal_customers_table_menu_5")}
                </div>
              )}
              {row.original.actionStatus === "skip_weekly" && (
                <div
                  className="dropdown-item"
                  onClick={handleCompliteWeeklyQuestionnaire(row)}
                >
                  <CreditIcon color="inherit" />
                  {t("portal_customers_table_menu_5")}
                </div>
              )}
              {row.original.actionStatus === "give_assessment" && (
                <div
                  className="dropdown-item"
                  onClick={() => push(`/admin/orders/new/${row.original.id}`)}
                >
                  <PlusCircle />
                  {row.original.lStatus === "assessment_needed"
                    ? t("portal_customers_table_menu_3")
                    : t("portal_customers_table_menu_10")}
                </div>
              )}
              {(row.original.actionStatus === "update_assessment" ||
                row.original.actionStatus === "skip_weekly" ||
                row.original.actionStatus === "skip_initial") && (
                <div
                  className="dropdown-item"
                  onClick={handleChangeUserQuestionnaireStatus(row)}
                >
                  <PlusCircle />
                  {t("portal_customers_table_menu_3")}
                </div>
              )}
              {row.original.actionStatus === "send_assessment" && (
                <div
                  className="dropdown-item"
                  onClick={() =>
                    push(
                      `/admin/orders/send/${row.original.lastOrderId}/${row.original.lastOrderTreatmentId}`
                    )
                  }
                >
                  <PlusCircle /> {t("portal_customers_table_menu_6")}
                </div>
              )}
              {row.original.isRefund && (
                <div
                  className="dropdown-item"
                  onClick={handleRequestRefund(row)}
                >
                  <PlusCircle /> {t("portal_customers_table_menu_7")}
                </div>
              )}
            </TableDropdown>
          ),
        },
      ]);
    }
  );

  const handleSetWaitingDiagnoseStatus = (row) => async () => {
    await api.post("updateUser", {
      id: row.original.id,
      skipQuestionnaire: true,
      status: "waiting_diagnose",
    });
    updateStorageData(row.original.id, row.original.lastOrderId);
  };

  const handleCompliteWeeklyQuestionnaire = (row) => async () => {
    await api.post("updateUser", {
      id: row.original.id,
      weeklyQuestionnaireDone: true,
    });
    updateStorageData(row.original.id, row.original.lastOrderId);
  };

  const handleChangeUserQuestionnaireStatus = (row) => async () => {
    if (
      row.original.actionStatus === "skip_weekly" ||
      row.original.actionStatus === "skip_initial"
    ) {
      await api.post("updateUser", {
        id: row.original.id,
        ...(row.original.actionStatus === "skip_initial"
          ? {
              skipQuestionnaire: true,
              status: "waiting_diagnose",
            }
          : {
              weeklyQuestionnaireDone: true,
            }),
      });
      updateStorageData(row.original.id, row.original.lastOrderId);
    }
    push(`/admin/orders/new/${row.original.id}/${row.original.lastOrderId}`);
  };

  const handleRequestRefund = (row) => async () => {
    await api.post("updateOrder", {
      id: row.original.lastOrderId,
      status: "request_refund",
    });
    await onRefund(
      row.original.lastOrderPaymentId,
      row.original.id,
      row.original.firstName + " " + row.original.lastName,
      user
    );
  };

  const updateStorageData = async (userId, orderId) => {
    const { data: userData } = await api.get("userByID", {
      params: { userId },
    });

    const { data: order } = await api.get("orderByID", {
      params: { usorderIderId },
    });

    await updateStorageOrders(
      user.id,
      {
        ...userData,
        name:
          (userData?.firstName &&
            userData?.lastName &&
            `${userData?.firstName} ${userData?.lastName}`) ||
          userData?.name ||
          "-",
      },
      order,
      order.id
    );
  };

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const usersArr = await getUsers(user.id);
      dispatch(setCustomers(usersArr));
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchUsers();
    }
  }, [user.id]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems={"center"} justifyContent="space-between">
        <div>
          <h2 className="title">{t("portal_customers_top_title")}</h2>
          {!isLoading && !isError && (
            <p className="descr">
              {users?.length === 0
                ? t("portal_customers_top_description")
                : t("portal_customers_top_description_2")}
            </p>
          )}
        </div>
        {!isLoading && !isError && users?.length === 0 && (
          <Link to="/admin/customers/new">
            <button type="button" className="custom-btn">
              {t("portal_customers_top_button")}
            </button>
          </Link>
        )}
      </Flex>

      {isLoading && (
        <Flex mx={"auto"} mt="20px">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <p className="error">{t("portal_error_message")}</p>
      )}
      {!isLoading && !isError ? (
        users?.length ? (
          <>
            <Flex
              alignItems={{ md: "center", base: "flex-start" }}
              justifyContent="space-between"
              flexDirection={{
                base: "column",
                md: "row",
              }}
              mb={{ md: 0, base: "20px" }}
            >
              <label className="custom-input-wrapper">
                <SearchIcon color={"#3D2541"} w="18px" h="18px" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onChange(e.target.value);
                  }}
                  placeholder={t("portal_customers_table_search_placeholder")}
                  className={"custom-input"}
                />
              </label>
              <span className="custom-link">
                <Link to="/admin/customers/new">
                  {t("portal_customers_new_link")}
                </Link>
              </span>
            </Flex>

            <div className="table table-with-action" {...getTableProps()}>
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <div
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="table-sort">
                          {column.canSort ? (
                            <>
                              {column?.isSortedDesc && <> &#9660;</>}
                              {column?.isSortedDesc === false && <> &#9650;</>}
                              {column?.isSortedDesc === undefined && (
                                <span className="sort-arrows">
                                  <span>&#9650;</span>
                                  <span>&#9660;</span>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="tbody" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <div className="tr" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <div className="td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <Flex alignItems="center" justifyContent="space-between">
              <Box mt="1rem">{user.customersIsUpdating && <DotsLoader />}</Box>

              <Pagination
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
              />
            </Flex>
          </>
        ) : (
          <p className="error">{t("portal_customers_empty_table")}</p>
        )
      ) : null}
    </Flex>
  );
};

export default ExpertsUsers;
