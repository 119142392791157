import { Box, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import LineChart from "components/Charts/LineChart";
import { PersonIcon } from "components/Icons/Icons";
import { CreditIcon } from "components/Icons/Icons";
import { DocumentIcon } from "components/Icons/Icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ChartStatistics from "./components/ChartStatistics";
import { useMemo } from "react";
import moment from "moment";
import { FaBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ActivePatients } from "components/Icons/Icons";
import { ShipmentIcon } from "components/Icons/Icons";
import { Banknote } from "components/Icons/Icons";
import TableDropdown from "components/TableDropdown";
import { PlusCircle } from "components/Icons/Icons";
import { getTierLevel } from "helpers";
import { getUserActionStatus } from "helpers";
import { numberWithCommas } from "helpers";
import { getAssessmentsByExpertID } from "dbManager";
import { getMaxRange } from "helpers";
import DotsLoader from "components/DotsLoader";
import { onRefund } from "dbManager";
import { requestPayment } from "helpers";
import api from "centralApi";

export default function Dashboard() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const user = useSelector((state) => state.user);

  const [chartData, setChartData] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState(0);
  const [assessments, setAssessments] = useState([]);
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [limit, setLimit] = useState(0);
  const [discount, setDiscount] = useState(null);
  const [commission, setCommission] = useState(0);
  const [commissionPaid, setCommissionPaid] = useState(0);
  const [commissionDue, setCommissionDue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [chartIsLoading, setChartIsLoading] = useState(true);
  const [tierIsLoading, setTierIsLoading] = useState(true);
  const [activeUsersIsLoading, setActiveUsersIsLoading] = useState(true);
  const [ordersIsLoading, setOrdersIsLoading] = useState(true);
  const [usersIsLoading, setUsersIsLoading] = useState(true);
  const [assessmentsIsLoading, setAssessmentsIsLoading] = useState(true);
  const [paymentsIsLoading, setPaymentsIsLoading] = useState(true);
  const [commissionIsLoading, setCommissionIsLoading] = useState(true);
  const [commissionPaidIsLoading, setCommissionPaidIsLoading] = useState(true);
  const [commissionDueIsLoading, setCommissionDueIsLoading] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [sentRequestIds, setSentRequestIds] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [update, setUpdate] = useState(false);

  const toast = useToast();

  const styles = useMemo(() => {
    if (limit === 5) {
      return {
        borderTopColor: "#92001A",
        borderRightColor: "#F6F0EC",
        borderBottomColor: "#ECE4DE",
        borderLeftColor: "#DED6D1",
      };
    } else if (limit === 10) {
      return {
        borderTopColor: "#DED6D1",
        borderRightColor: "#92001A",
        borderBottomColor: "#F6F0EC",
        borderLeftColor: "#ECE4DE",
      };
    } else if (limit === 15) {
      return {
        borderTopColor: "#ECE4DE",
        borderRightColor: "#F6F0EC",
        borderBottomColor: "#92001A",
        borderLeftColor: "#DED6D1",
      };
    } else if (limit === 20) {
      return {
        borderTopColor: "#DED6D1",
        borderRightColor: "#ECE4DE",
        borderBottomColor: "#F6F0EC",
        borderLeftColor: "#92001A",
      };
    } else {
      return {
        borderTopColor: "#f1f1f1",
        borderRightColor: "#F6F0EC",
        borderBottomColor: "#ECE4DE",
        borderLeftColor: "#DED6D1",
      };
    }
  }, [limit]);

  const fetchData = async () => {
    setIsUpdating(true);
    try {
      let { data } = await api.get("ordersByExpertID", {
        params: { expertId: user.id },
      });
      let ordersArr = data?.list || [];
      ordersArr?.filter((i) => !i?.deleted);

      setOrders(ordersArr);
      setOrdersIsLoading(false);

      const { data: userByID } = await api.get("userByID", {
        params: { userId: user.id },
      });

      let tierLevel = null;

      if (userByID?.tierDiscount) {
        setDiscount(userByID.tierDiscount);
      } else {
        if (ordersArr?.length) {
          tierLevel = await getTierLevel(user.id);
          setLimit(tierLevel);
        }
      }

      setTierIsLoading(false);

      let usersIds = [];
      let usersData = [];

      const {
        data: { list: usersByExpertID },
      } = await api.get("usersByExpertID", { params: { expertId: user.id } });

      if (usersByExpertID.length) {
        usersByExpertID.forEach((i) => {
          usersIds.push(i.id);
          usersData.push({
            ...i,
            name:
              (i?.firstName &&
                i?.lastName &&
                `${i?.firstName} ${i?.lastName}`) ||
              i?.name ||
              "-",
          });
        });
      }
      setUsers(usersData);
      setUsersIsLoading(false);

      const assessmentArr = await getAssessmentsByExpertID(user.id);
      setAssessments(assessmentArr);
      setAssessmentsIsLoading(false);

      const { data: paymentsArr } = await api.get("paymentsByExpertID", {
        params: { expertId: user.id, status: "complete" },
      });

      setPayments(paymentsArr);
      setPaymentsIsLoading(false);

      const comm =
        paymentsArr
          .map((i) => ({
            commissions:
              i?.order_ids && i.order_ids?.length
                ? i.consumerTotal - i.total
                : 0,
          }))
          .reduce((prev, curr) => prev + curr.commissions, 0) / 100;

      setCommission(comm || 0);
      setCommissionIsLoading(false);

      const commPaid =
        paymentsArr
          .map((i) => ({
            commissionsPaid:
              i?.order_ids && i.order_ids?.length && i.payment_type === "stripe"
                ? i.consumerTotal - i.total
                : 0,
          }))
          .reduce((prev, curr) => prev + curr.commissionsPaid, 0) / 100;

      setCommissionPaid(commPaid || 0);
      setCommissionPaidIsLoading(false);

      const commDue =
        paymentsArr
          .map((i) => ({
            commissionsDue:
              i?.order_ids &&
              i.order_ids?.length &&
              i.payment_type === "shopify"
                ? i.consumerTotal - i.total
                : 0,
          }))
          .reduce((prev, curr) => prev + curr.commissionsDue, 0) / 100;

      setCommissionDue(commDue || 0);
      setCommissionDueIsLoading(false);

      let activeUsersCnt = 0;
      let activeUsersArr = [];
      let uniqActiveUsers = {};
      let filteredTableData = [];

      const {
        data: expertPackages,
      } = await api.get("getLastPackagesByExpertID", {
        params: { expertId: user.id },
      });

      if (usersData.length) {
        for (let i = 0; i < usersData.length; i++) {
          const user = usersData[i];

          const userOrders = ordersArr
            .filter(
              (j) =>
                j.user_id === user.id && j.status !== "canceled" && !j?.deleted
            )
            .sort((a, b) =>
              new Date(a?.created).getTime() < new Date(b?.created).getTime()
                ? -1
                : 1
            );

          if (userOrders.length) {
            const order = userOrders[userOrders.length - 1];
            const userPackage = expertPackages.find(
              (i) => i.user_id === user.id
            );

            let {
              lStatus,
              actionStatus,
              lastPackage,
              isRefund,
            } = await getUserActionStatus(user, order, true, userPackage);

            const lastOrder = order.created;
            const lastOrderStatus = order.status;
            const lastOrderType = order.type;
            const lastOrderRef = order.ref?.replace("LD", "");
            const lastOrderId = order.id;
            const lastOrderTreatmentId = order?.treatmentId;
            const lastOrderPaymentId = order?.paymentId;

            usersData[i].lStatus = lStatus;
            usersData[i].actionStatus = actionStatus;
            usersData[i].lastPackage = lastPackage;
            usersData[i].lastOrder = lastOrder;
            usersData[i].lastOrderStatus = lastOrderStatus;
            usersData[i].lastOrderType = lastOrderType;
            usersData[i].lastOrderRef = lastOrderRef;
            usersData[i].lastOrderId = lastOrderId;
            usersData[i].lastOrderTreatmentId = lastOrderTreatmentId;
            usersData[i].lastOrderPaymentId = lastOrderPaymentId;
            usersData[i].isRefund = isRefund;

            if (lStatus !== user?.lStatus) {
              await api.post("updateUser", { id: user.id, lStatus: lStatus });
            }
            if (lStatus === "active") {
              activeUsersCnt++;
              activeUsersArr.push({ id: user.id, created: user.created });
            }
          } else {
            usersData[i].lStatus = "registered";
            usersData[i].actionStatus = "give_assessment";
          }
        }

        setActiveUsers(activeUsersCnt);

        for (let i = 0; i <= new Date().getMonth(); i++) {
          uniqActiveUsers[i] =
            [
              ...new Set(
                activeUsersArr
                  .filter(
                    (j) =>
                      moment(j?.created)?.year() < new Date().getFullYear() ||
                      moment(j?.created)?.month() <= i
                  )
                  .map((k) => k?.id)
              ),
            ]?.length || 0;
        }

        setChartData(Object.values(uniqActiveUsers));
        setChartIsLoading(false);

        filteredTableData = usersData
          .filter(
            (i) =>
              i?.lStatus === "action_needed" ||
              i?.lStatus === "assessment_needed"
          )
          .sort(
            (a, b) =>
              new Date(b?.lastOrder).getTime() -
              new Date(a?.lastOrder).getTime()
          );

        setTableData(filteredTableData);
      } else {
        setChartIsLoading(false);
      }

      setActiveUsersIsLoading(false);
      setTableIsLoading(false);

      localStorage.setItem(
        `dashboard_${user.id}`,
        JSON.stringify({
          chartData: Object.values(uniqActiveUsers),
          users: usersData,
          activeUsers: activeUsersCnt,
          assessments: assessmentArr,
          orders: ordersArr,
          payments: paymentsArr,
          limit: tierLevel,
          discount: userByID.tierDiscount,
          commission: comm || 0,
          commissionPaid: commPaid || 0,
          commissionDue: commDue || 0,
          tableData: filteredTableData,
        })
      );
    } catch (err) {
      console.log("err", err);
      setIsError(true);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const storageDashboard = JSON.parse(
      localStorage.getItem(`dashboard_${user.id}`)
    );

    if (storageDashboard && Object.keys(storageDashboard)?.length) {
      setChartData(storageDashboard?.chartData);
      setChartIsLoading(false);
      setUsers(storageDashboard?.users);
      setUsersIsLoading(false);
      setActiveUsers(storageDashboard?.activeUsers);
      setActiveUsersIsLoading(false);
      setAssessments(storageDashboard?.assessments);
      setAssessmentsIsLoading(false);
      setOrders(storageDashboard?.orders);
      setOrdersIsLoading(false);
      setPayments(storageDashboard?.payments);
      setPaymentsIsLoading(false);
      setLimit(storageDashboard?.limit);
      setDiscount(storageDashboard?.discount);
      setTierIsLoading(false);
      setCommission(storageDashboard?.commission || 0);
      setCommissionIsLoading(false);
      setCommissionPaid(storageDashboard?.commissionPaid || 0);
      setCommissionPaidIsLoading(false);
      setCommissionDue(storageDashboard?.commissionDue || 0);
      setCommissionDueIsLoading(false);
      setTableData(storageDashboard?.tableData);
      setTableIsLoading(false);
    }

    if (user.id || update) {
      fetchData();
    }
  }, [user.id, update]);

  return (
    <Flex flexDirection="column">
      <h2 className="title" style={{ marginBottom: "26px" }}>
        {t("portal_dashboard_top_title")}
      </h2>

      {isError && <p className="error">{t("portal_error_message")}</p>}
      {!isError ? (
        <>
          {paymentsIsLoading || ordersIsLoading || usersIsLoading ? (
            <Flex mx={"auto"} mb="20px">
              <Spinner color="red.700" size="md" />
            </Flex>
          ) : (
            payments.length < 2 && (
              <Flex
                alignItems={"center"}
                justifyContent="space-between"
                mb="20px"
                className="dashboard-card"
              >
                <div>
                  <h3
                    className="title"
                    style={{
                      fontSize: "22px",
                      lineHeight: "26px",
                      marginBottom: "-4px",
                    }}
                  >
                    {(users.length === 0 &&
                      t("portal_dashboard_top_title_2")) ||
                      (orders.length === 0 &&
                        t("portal_dashboard_top_title_3")) ||
                      (payments.length === 0 &&
                        t("portal_dashboard_top_title_4")) ||
                      (payments.length > 0 &&
                        payments.length < 2 &&
                        t("portal_dashboard_top_title_5"))}
                  </h3>
                  <p className="descr">
                    {(users.length === 0 &&
                      t("portal_dashboard_top_description")) ||
                      (orders.length === 0 &&
                        t("portal_dashboard_top_description_2")) ||
                      (payments.length === 0 &&
                        t("portal_dashboard_top_description_3")) ||
                      (payments.length > 0 && payments.length < 2 && (
                        <>
                          {t("portal_dashboard_top_description_4")}{" "}
                          <a
                            href="mailto:welcome@libi.com"
                            style={{
                              color: "#92001A",
                              textDecoration: "underline",
                            }}
                          >
                            welcome@libi.com
                          </a>
                        </>
                      ))}
                  </p>
                </div>
                {payments.length < 1 && (
                  <Link
                    to={
                      (users.length === 0 && "/admin/customers/new") ||
                      (orders.length === 0 && "/admin/orders/new") ||
                      (payments.length === 0 && "/admin/payments")
                    }
                  >
                    <button type="button" className="custom-btn">
                      {t("portal_dashboard_top_button")}
                    </button>
                  </Link>
                )}
              </Flex>
            )
          )}
          <Flex gap="20px" flexDirection={{ lg: "row", sm: "column" }}>
            <Box flex={"1 0 0"}>
              <div
                className="dashboard-card"
                style={{ marginBottom: "20px", height: "380px" }}
              >
                {chartIsLoading || tierIsLoading ? (
                  <Flex alignItems="center" justifyContent="center" h="100%">
                    <Spinner color="red.700" size="xl" />
                  </Flex>
                ) : (
                  <LineChart data={chartData} discount={discount} />
                )}
              </div>
              <div className="dashboard-card">
                <Flex gap="22px" flexWrap={{ base: "wrap", lg: "nowrap" }}>
                  {usersIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Patients <br /> Registered
                        </>
                      }
                      value={users.length}
                      amount={users.length}
                      max={getMaxRange(users.length)}
                      icon={<PersonIcon color="#fff" />}
                    />
                  )}
                  {activeUsersIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Patients <br /> Active
                        </>
                      }
                      value={activeUsers}
                      amount={activeUsers}
                      max={getMaxRange(activeUsers)}
                      icon={<ActivePatients color="#fff" />}
                    />
                  )}
                  {assessmentsIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Diagnosis <br /> given
                        </>
                      }
                      value={assessments.length}
                      amount={assessments.length}
                      max={getMaxRange(assessments.length)}
                      icon={<DocumentIcon color="#fff" />}
                    />
                  )}
                  {ordersIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Orders <br /> number
                        </>
                      }
                      value={orders.length}
                      amount={orders.length}
                      max={getMaxRange(orders.length)}
                      icon={<ShipmentIcon color="#fff" />}
                    />
                  )}
                </Flex>
              </div>
            </Box>
            <Box flex={"1 0 0"}>
              <div
                className="dashboard-card"
                style={{ marginBottom: "20px", height: "380px" }}
              >
                {tierIsLoading ? (
                  <Flex alignItems="center" justifyContent="center" h="100%">
                    <Spinner color="red.700" size="xl" />
                  </Flex>
                ) : (
                  <div className="dashboard-center-card">
                    <Flex
                      alignItems={"center"}
                      mb="12px"
                      mx="auto"
                      fontSize={14}
                      fontWeight={500}
                      gap="10px"
                    >
                      <Box
                        w={"10px"}
                        h={"10px"}
                        borderRadius="50%"
                        bg={"#92001A"}
                      ></Box>
                      Your current tier
                    </Flex>
                    <div className="dashboard-circle" style={styles}>
                      <div className="dashboard-circle-border">
                        <div className="dashboard-circle-title">
                          <span>
                            {discount ? discount + "%" : "Tier Level"}
                          </span>
                          Commissions
                        </div>
                      </div>
                      <span
                        className="dashboard-circle-5"
                        style={{ color: limit === 5 ? "#fff" : "#3D2541" }}
                      >
                        5%
                      </span>
                      <span
                        className="dashboard-circle-10"
                        style={{ color: limit === 10 ? "#fff" : "#3D2541" }}
                      >
                        10%
                      </span>
                      <span
                        className="dashboard-circle-15"
                        style={{ color: limit === 15 ? "#fff" : "#3D2541" }}
                      >
                        15%
                      </span>
                      <span
                        className="dashboard-circle-20"
                        style={{ color: limit === 20 ? "#fff" : "#3D2541" }}
                      >
                        20%
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="dashboard-card">
                <Flex gap="22px" flexWrap={{ base: "wrap", lg: "nowrap" }}>
                  {paymentsIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Total orders <br /> value
                        </>
                      }
                      value={
                        "£" +
                        numberWithCommas(
                          (
                            payments?.reduce(
                              (prev, curr) => prev + Number(curr?.total || 0),
                              0
                            ) / 100
                          ).toFixed(2)
                        )
                      }
                      amount={(
                        payments?.reduce(
                          (prev, curr) => prev + Number(curr?.total || 0),
                          0
                        ) / 100
                      )?.toFixed(2)}
                      max={getMaxRange(
                        (
                          payments?.reduce(
                            (prev, curr) => prev + Number(curr?.total || 0),
                            0
                          ) / 100
                        ).toFixed(0)
                      )}
                      icon={<CreditIcon color="#fff" />}
                    />
                  )}
                  {commissionIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Commissions <br /> earned
                        </>
                      }
                      value={"£" + numberWithCommas(commission?.toFixed(2))}
                      amount={commission?.toFixed(2)}
                      max={getMaxRange(commission?.toFixed(0))}
                      icon={<Banknote color="#fff" />}
                    />
                  )}
                  {commissionPaidIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Commissions <br /> paid
                        </>
                      }
                      value={"£" + numberWithCommas(commissionPaid?.toFixed(2))}
                      amount={commissionPaid?.toFixed(2)}
                      max={getMaxRange(commissionPaid?.toFixed(0))}
                      icon={<Banknote color="#fff" />}
                    />
                  )}
                  {commissionDueIsLoading ? (
                    <Flex mx={"auto"} flex="1 1 0">
                      <Spinner color="red.700" size="md" />
                    </Flex>
                  ) : (
                    <ChartStatistics
                      title={
                        <>
                          Commissions <br /> due
                        </>
                      }
                      value={"£" + numberWithCommas(commissionDue?.toFixed(2))}
                      amount={commissionDue?.toFixed(2)}
                      max={getMaxRange(commissionDue?.toFixed(0))}
                      icon={<Banknote color="#fff" />}
                    />
                  )}
                </Flex>
              </div>
            </Box>
          </Flex>
          {tableIsLoading ? (
            <Flex mx={"auto"} mt="20px">
              <Spinner color="red.700" size="xl" />
            </Flex>
          ) : (
            tableData?.length > 0 && (
              <>
                <Flex
                  alignItems="center"
                  gap="8px"
                  mt="33px"
                  mb="13px"
                  fontSize={16}
                  fontWeight="600"
                >
                  <FaBell /> Actions{" "}
                  <Text color="#92001A" fontSize="16px" fontWeight="600">
                    {tableData.length}
                  </Text>
                </Flex>
                <Flex
                  className="tbody"
                  gap="16px 20px"
                  flexDirection={"column"}
                  mb="20px"
                >
                  {tableData.map((i, idx) => (
                    <Flex
                      key={idx}
                      flex="1 0 0"
                      alignItems="center"
                      background={"#FFFFFF"}
                      boxShadow={"0px 0px 8px rgba(0, 0, 0, 0.1)"}
                      borderRadius={"6px"}
                    >
                      <div className="td" style={{ width: "130px" }}>
                        <Link
                          to={
                            i?.actionStatus === "send_assessment"
                              ? `/admin/orders/send/${i?.lastOrderId}/${i?.lastOrderTreatmentId}`
                              : `/admin/order/${
                                  i?.lastOrderId
                                }?type=${i?.lastOrderType
                                  .replace("A", "")
                                  .replace("B", "")}`
                          }
                        >
                          <u>{i?.lastOrderRef}</u>
                        </Link>
                      </div>
                      <div
                        className="td"
                        style={{ width: "130px" }}
                        title={moment(i.lastOrder).format("HH:mm MMM DD YYYY")}
                      >
                        {moment(i.lastOrder).format("DD/MM/YYYY") || "-"}
                      </div>
                      <div
                        className="td"
                        style={{ width: "220px" }}
                        title={i?.email}
                      >
                        <Link to={`/admin/customers/${i?.id}`}>
                          <u>{i?.name}</u>
                        </Link>
                      </div>
                      <div
                        className="td"
                        style={{ width: "220px", fontWeight: 500 }}
                      >
                        <span
                          style={{
                            color:
                              [
                                "paid_shopify",
                                "paid_stripe",
                                "delivered",
                              ].includes(i?.lastOrderStatus) &&
                              i?.actionStatus !== "send_assessment"
                                ? "#219653"
                                : "#DF710C",
                          }}
                        >
                          {i?.actionStatus === "send_assessment" ? (
                            "Completion needed"
                          ) : (
                            <>
                              {i?.lastOrderStatus === "payment_needed"
                                ? "Payment needed"
                                : ""}
                              {i?.lastOrderStatus === "paid_shopify" ||
                              i?.lastOrderStatus === "paid_stripe"
                                ? "Paid"
                                : ""}
                              {i?.lastOrderStatus === "delivery_in_process"
                                ? "Delivery In-Process"
                                : ""}
                              {i?.lastOrderStatus === "delivery_needed"
                                ? "Delivery Needed"
                                : ""}
                              {i?.lastOrderStatus === "delivered"
                                ? "Delivered"
                                : ""}
                              {i?.weeklyQuestionnaireDone &&
                              i?.lastOrderStatus === "questionnaire_needed"
                                ? "Assessment Needed"
                                : ""}
                              {!i?.weeklyQuestionnaireDone &&
                              i?.lastOrderStatus === "questionnaire_needed"
                                ? "Questionnaire Needed"
                                : ""}
                              {i?.lastOrderStatus === "canceled"
                                ? "Canceled"
                                : ""}
                              {i?.lastOrderStatus === "request_refund"
                                ? "Refund request"
                                : ""}
                              {i?.lastOrderStatus === "refunded"
                                ? "Refunded"
                                : ""}
                              {i?.lastOrderStatus === "refund_rejected"
                                ? "Refund rejected"
                                : ""}
                            </>
                          )}
                        </span>
                      </div>
                      {(i?.lastOrderStatus === "paid_shopify" ||
                        i?.lastOrderStatus === "paid_stripe") &&
                      i?.lastOrderType === "rb" ? (
                        <div className="td">
                          <Link
                            to={`/admin/orders/new/${i?.id}/${i?.lastOrderId}`}
                          >
                            <u>{t("portal_customers_table_menu_11")}</u>
                          </Link>
                        </div>
                      ) : null}
                      <Box ml="auto" mr="16px">
                        <TableDropdown>
                          {i?.actionStatus === "payment_needed" && (
                            <>
                              <div
                                className="dropdown-item"
                                onClick={() =>
                                  push(`/admin/payments?oid=${i?.lastOrderId}`)
                                }
                              >
                                <CreditIcon color="inherit" />
                                {t("portal_orders_table_menu_2")}
                              </div>
                              <div
                                className="dropdown-item"
                                onClick={() =>
                                  requestPayment(
                                    i?.id,
                                    i?.email,
                                    sentRequestIds,
                                    setSentRequestIds,
                                    toast
                                  )
                                }
                              >
                                <CreditIcon color="inherit" />
                                {sentRequestIds.includes(i?.id)
                                  ? t("portal_customers_table_menu_8")
                                  : t("portal_customers_table_menu_4")}
                              </div>
                            </>
                          )}
                          {i?.actionStatus === "skip_initial" && (
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                api
                                  .post("updateUser", {
                                    id: i?.id,
                                    skipQuestionnaire: true,
                                    ...(i?.status === "questionnaire1" && {
                                      status: "waiting_delivery",
                                    }),
                                  })
                                  .then(() => {
                                    setUpdate(true);
                                  });
                              }}
                            >
                              <CreditIcon color="inherit" />
                              {t("portal_customers_table_menu_5")}
                            </div>
                          )}
                          {i?.actionStatus === "skip_weekly" && (
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                api
                                  .post("updateUser", {
                                    id: i?.id,
                                    weeklyQuestionnaireDone: true,
                                  })
                                  .then(() => {
                                    setUpdate(true);
                                  });
                              }}
                            >
                              <CreditIcon color="inherit" />
                              {t("portal_customers_table_menu_5")}
                            </div>
                          )}
                          {i?.actionStatus === "give_assessment" && (
                            <div
                              className="dropdown-item"
                              onClick={() => push(`/admin/orders/new/${i?.id}`)}
                            >
                              <PlusCircle />
                              {i.lStatus === "assessment_needed"
                                ? t("portal_customers_table_menu_3")
                                : t("portal_customers_table_menu_10")}
                            </div>
                          )}
                          {i?.actionStatus === "update_assessment" && (
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                push(
                                  `/admin/orders/new/${i?.id}/${i?.lastOrderId}`
                                )
                              }
                            >
                              <PlusCircle />{" "}
                              {t("portal_customers_table_menu_3")}
                            </div>
                          )}
                          {i?.actionStatus === "send_assessment" && (
                            <div
                              className="dropdown-item"
                              onClick={() =>
                                push(
                                  `/admin/orders/send/${i?.lastOrderId}/${i?.lastOrderTreatmentId}`
                                )
                              }
                            >
                              <PlusCircle />{" "}
                              {t("portal_customers_table_menu_6")}
                            </div>
                          )}
                          {i?.isRefund && (
                            <div
                              className="dropdown-item"
                              onClick={async () => {
                                await api.post("updateOrder", {
                                  id: i?.lastOrderId,
                                  status: "request_refund",
                                });
                                await onRefund(
                                  i?.lastOrderPaymentId,
                                  i?.id,
                                  i?.firstName + " " + i?.lastName,
                                  user
                                );
                              }}
                            >
                              <PlusCircle />{" "}
                              {t("portal_customers_table_menu_7")}
                            </div>
                          )}
                        </TableDropdown>
                      </Box>
                    </Flex>
                  ))}
                </Flex>
              </>
            )
          )}
          {isUpdating && (
            <Box my="20px">
              <DotsLoader />
            </Box>
          )}
        </>
      ) : null}
    </Flex>
  );
}
