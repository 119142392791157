import { Flex, Spinner, Text } from '@chakra-ui/react'
import api from 'centralApi'
import { ReactComponent as InfoIcon } from "assets/svg/info-circle.svg"
import Counter from 'components/Counter'
import { collection, getDocs } from 'firebase/firestore'
import { updateStorageOrders } from 'helpers'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { db } from '../../../firebase'

const IPForm = ({
  userData,
  orderId,
  prices,
  currency
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const user = useSelector(state => state.user)

  const [products, setProducts] = useState([])
  const [quantities, setQuantities] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [createIsLoading, setCreateIsLoading] = useState(false)
  const [createIsError, setCreateIsError] = useState(false)
  const [idError, setIdError] = useState(false)

  const total = useMemo(() => {
    return quantities.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0)
  }, [quantities])

  const totalQty = useMemo(() => {
    return quantities.reduce((prev, curr) => prev + curr.quantity, 0)
  }, [quantities])

  const onSubmit = async () => {
    try {
      setCreateIsLoading(true)

      if (!userData?.id) {
        setIdError(true);
        return;
      }

      const orderProducts = quantities
        .filter(i => i?.quantity > 0)
        .map(j => ({ ref: j?.ref, quantity: j?.quantity }))

      let newOrder = {}

      if (orderId) {
        const { data: orderData } = await api.get('orderByID', { params: { orderId } })

        if (orderData && (orderData?.status === 'paid_shopify' || orderData?.status === 'paid_stripe')) {
          const orderObj = {
            type: 'ip',
            product: {
              products: orderProducts
            },
            total,
            expertId: user.id,
            status: 'delivery_in_process',
            submitted: 1,
            currency: currency === 'HU' ? 'eur' : 'gbp'
          }
          newOrder = orderObj
          await api.post('updateOrder', { id: orderId, ...orderObj })
        }
      } else {
        const { data: userByID } = await api.get('userByID', { params: { userId: userData?.id } })

        if (userByID && userByID?.status !== 'active' && userByID?.status !== 'waiting_delivery') {
          await api.post('updateUser', { id: userData?.id, status: 'waiting_payment' })
        }

        const orderObj = {
          ref: 'LD' + Math.floor(Date.now() / 1000),
          type: 'ip',
          product: {
            products: orderProducts
          },
          total,
          expertId: user.id,
          paymentId: null,
          status: 'payment_needed',
          user_id: userData?.id,
          submitted: 1,
          currency: currency === 'HU' ? 'eur' : 'gbp'
        }
        const result = await api.post('createOrder', orderObj)

        newOrder = {id: result?.data?.insertId, ...orderObj}
      }

      await updateStorageOrders(user.id, userData, newOrder, orderId)

      history.push('/admin/orders')
    } catch (err) {
      console.log('err', err)
      setCreateIsError(true)
    } finally {
      setCreateIsLoading(false)
    }
  }

  const getIndividualProducts = async () => {
    try {
      setIsLoading(true)

      let productsData = []
      const productsSnap = await getDocs(collection(db, 'Products'))
      if (!productsSnap.empty) {
        productsSnap.forEach(doc => {
          productsData.push({ id: doc.id, ...doc.data() })
        })
      }

      let ip = [{
        ref: 'pShipping',
        title: t('portal_experts_view_shipping_fee'),
        price: prices[`ipShippingPricePartner0${currency}`],
        quantity: 0,
      }]
      const { data: userByID } = await api.get('userByID', { params: { userId: user.id } })

      if (userByID) {
        if (userByID?.individualProducts?.length) {
          userByID.individualProducts.forEach(i => {
            const p = productsData.find(j => j.ref === i)
            ip.push({
              ref: i,
              title: p?.title,
              price: prices[`i${i}PricePartner0${currency}`]
            })
          })
        }
      }

      setProducts(ip)
      setQuantities(ip.map(i => ({ ...i, quantity: i?.quantity || 0 })))
    } catch (err) {
      console.log('err', err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if(currency) {
      getIndividualProducts()
    }
  }, [currency])

  return (
    <div>
      <Text mb="20px" className="title">{t('portal_orders_new_products_choise_3')}</Text>

      {isLoading && <Flex textAlign={"center"}><Spinner color="red.700" size="xl" /></Flex>}
      {!isLoading && isError && <p className="error" style={{ textAlign: 'center' }}>{t('portal_error_message')}</p>}
      {!isLoading && !isError && <>
        <div className="individual-products-table-scroll">
          <table className="individual-products-table">
            <thead>
              <tr className="individual-products-table-tr">
                <th className="individual-products-table-th">
                  {t('Product Name')}
                </th>
                <th className="individual-products-table-th" style={{ width: '215px' }}>
                  <span className='individual-products-table-td-accent' style={{ justifyContent: 'center' }}>
                    {t('Quantity ')}
                  </span>
                </th>
                <th className="individual-products-table-th">
                  {t('Price')}
                </th>
              </tr>
            </thead>
            <tbody className="individual-products-table-body">
              {products.map((i, index) => (
                <tr key={i?.ref || index} className="individual-products-table-tr">
                  <td className="individual-products-table-td">
                    {i?.title}
                  </td>
                  <td className="individual-products-table-td" style={{ width: '215px' }}>
                    <span className='individual-products-table-td-accent' style={{ justifyContent: 'center' }}>
                      <Counter
                        value={quantities.find(j => j?.ref === i?.ref)?.quantity}
                        setValue={value =>
                          setQuantities(prev =>
                            prev.map(j => j?.ref === i?.ref ? { ...j, quantity: value } : j)
                          )
                        }
                      />
                    </span>
                  </td>
                  <td className="individual-products-table-td">
                    {i?.price / 100} {currency === 'UK' ? '£' : '€'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {idError && <Text color="red.400" mt="20px">{t('portal_orders_new_qc_error_1')}</Text>}
        {createIsError && <Text color="red.400" mt="20px">{t('portal_error_message')}</Text>}

        <hr className='hr' />

        <Flex
          justifyContent={"space-between"}
          flexDirection={{ lg: 'row', sm: 'column' }}
          gap={{ lg: 0, sm: '20px' }}
        >
          <div className="new-order-price">
            <InfoIcon /> {t('portal_orders_new_total')}
            <span style={{marginRight: '20px'}}>{currency === 'UK' ? '£' : '€'} {total / 100}</span>
            Quantity total:<span>{totalQty}</span>
          </div>
          <button
            type="button"
            disabled={createIsLoading}
            className="custom-btn"
            onClick={onSubmit}
          >
            {createIsLoading ? t('portal_loading') : t('portal_orders_new_qc_bottom_button')}
          </button>
        </Flex>
      </>}
    </div>
  )
}

export default IPForm