import React, { useEffect, useState } from "react";
import ChooseEmail from "components/Authorization/ChooseEmail";
import { getAuth } from "firebase/auth";
import SignAgreement from "components/Authorization/SignAgreement";
import WaitingVerification from "components/Authorization/WaitingVerification";
import { useHistory } from "react-router-dom";
import api from "centralApi";

function SignUp() {
  const { push } = useHistory()

  const [renderComponent, setRenderComponent] = useState(null)

  const registrationProcedure = async (usedObj) => {
    const auth = getAuth();

    console.log("User object is all right?", usedObj, "Registration Procedure:", auth.currentUser);

    if (!auth.currentUser) {
      setRenderComponent(<ChooseEmail />);
    } if (usedObj) {
      if (usedObj.roleId == 2 && !usedObj?.agreementSigned) {
        await api.post('updateUser', {
          id: usedObj.id,
          agreementSigned: true
        })
        push("/admin/dashboard");
      } else if (!auth.currentUser.emailVerified) {
        push('/auth/email-confirmation')
      } else if (usedObj.agreementSigned) {
        if (usedObj.approved) {
          console.log("IsApproved", usedObj.approved);
          push("/admin/dashboard");
        } else {
          setRenderComponent(<WaitingVerification />);
        }
      } else {
        setRenderComponent(<SignAgreement />);
      }
    } else {
      setRenderComponent(<ChooseEmail />);
    }
  };

  useEffect(() => {
    getAuth().onAuthStateChanged(function (user) {
      console.log("Auth changed: ", user);

      if (user) {
        api.get('userByID', { params: { userId: user.uid } }).then(res => {
          registrationProcedure(res.data)
        })
      } else {
        console.log("Seems like we logged out");
        registrationProcedure(null);
      }
    });
  }, []);

  return (<>{renderComponent}</>)
}

export default SignUp;
