import { CopyIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import api from "centralApi"
import LibiLogo from "assets/img/libiLogoBlack.png"
import Checkbox from "components/Checkbox"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { useEffect, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { BsImage } from "react-icons/bs"
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import { useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import UkModulusChecking from 'uk-modulus-checking'
import * as yup from "yup"
import ExpertProducts from "../Experts/ExpertProducts"

const requireString = yup.string().required('This field is required')

const schema = yup.object({
  companyName: requireString,
  contactFirstName: requireString,
  address: requireString,
  contactLastName: requireString,
  city: requireString,
  country: requireString,
  postcode: requireString,
});

function ProfileEdit() {
  const { t } = useTranslation();

  const { expertId } = useParams()
  const history = useHistory()

  const toast = useToast()

  const { id, originalId, roleId } = useSelector(state => state.user)

  const [user, setUser] = useState({})
  const [phone, setPhone] = useState('')
  const [avatar, setAvatar] = useState('')
  const [imgSrc, setImgSrc] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [uodateIsLoading, setUodateIsLoading] = useState(false)
  const [updateIsError, setUpdateIsError] = useState(false)
  const [codeOrBankError, setCodeOrBankError] = useState(false)
  const [isIban, setIsIban] = useState(true)
  const [approveIsLoading, setApproveIsLoading] = useState(false)

  const [tierDiscount, setTierDiscount] = useState(null)
  const [rbDiscount, setRbDiscount] = useState(5)
  const [rbAvailability, setRbAvailability] = useState(true)
  const [qcAvailability, setQcAvailability] = useState(true)
  const [ipAvailability, setIpAvailability] = useState(false)
  const [individualProducts, setIndividualProducts] = useState([])
  const [currency, setCurrency] = useState('')
  const [countries, setCountries] = useState([])
  const [canEditOrder, setCanEditOrder] = useState(false)
  const [canSendToProduction, setCanSendToProduction] = useState(false)
  const [canSendMarketingMessages, setCanSendMarketingMessages] = useState(false)

  const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async data => {
    try {
      setUodateIsLoading(true)

      const isValid = new UkModulusChecking({
        accountNumber: data.bankAccountNumber,
        sortCode: data.sortcode,
      }).isValid();

      if (isIban && !data.iban) {
        setError('iban', { type: 'required', message: 'This field is required' });
        setUodateIsLoading(false)
        return
      } else if (!isIban && !data.bankAccountNumber && !data.sortcode) {
        setError('bankAccountNumber', { type: 'required', message: 'This field is required' });
        setError('sortcode', { type: 'required', message: 'This field is required' });
        setUodateIsLoading(false)
        return
      } else if (!isIban && !data.bankAccountNumber) {
        setError('bankAccountNumber', { type: 'required', message: 'This field is required' });
        setUodateIsLoading(false)
        return
      } else if (!isIban && !data.sortcode) {
        setError('sortcode', { type: 'required', message: 'This field is required' });
        setUodateIsLoading(false)
        return
      } else if (!isIban && !isValid) {
        setCodeOrBankError(true)
        setUodateIsLoading(false)
        return
      }

      await api.post('updateUser', { 
        id: user.id,
        companyName: data.companyName,
        contactFirstName: data.contactFirstName,
        address: data.address,
        contactLastName: data.contactLastName,
        city: data.city,
        country: data.country,
        postcode: data.postcode,
        contactPhone: phone,
        canEditOrder: Number(canEditOrder),
        canSendToProduction: Number(canSendToProduction),
        canSendMarketingMessages: Number(canSendMarketingMessages),
        ...(isIban
          ? { iban: data.iban }
          : {
            bankAccountNumber: data.bankAccountNumber,
            sortcode: data.sortcode,
          }
        ),
        ...((id !== originalId || expertId) && {
          ...(tierDiscount && { tierDiscount }),
          rbDiscount,
          qcADiscount: rbDiscount,
          qcBDiscount: rbDiscount,
          rbAvailability: Number(rbAvailability),
          qcAvailability: Number(qcAvailability),
          ipAvailability: Number(ipAvailability),
          individualProducts,
        })
      })

      if (avatar) {
        const storage = getStorage();
        const imageRef = ref(storage, `pictures/${avatar.name}`);
        await uploadBytes(imageRef, avatar)
        const url = await getDownloadURL(imageRef)
        await api.post('updateUser', {id: user.id, picture: url})
      }

      if (history.length > 1) {
        history.goBack();
      } else {
        window.close();
      }
    } catch (err) {
      console.log('err', err)
      setUpdateIsError(true)
    } finally {
      setUodateIsLoading(false)
    }
  }

  const handleChange = ev => {
    const file = ev.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setImgSrc(reader.result)
      setAvatar(file)
    };
  };

  const copyHandler = () => {
    navigator.clipboard.writeText(user?.inviteCode).then(() => {
      toast({
        title: 'Copied successfully',
        position: 'top-right',
        isClosable: true,
        status: 'success'
      })
    })
  }

  const getUser = async (userId) => {
    api.get('userByID', {params: { userId }})
      .then(({data}) => {
        if (data) {
          setUser(data)
          setValue('companyName', data?.companyName)
          setValue('contactFirstName', data?.contactFirstName)
          setValue('address', data?.address)
          setValue('contactLastName', data?.contactLastName)
          setValue('city', data?.city)
          setValue('country', data?.countryCode)
          setValue('bankAccountNumber', data?.bankAccountNumber)
          setValue('postcode', data?.postcode)
          setValue('sortcode', data?.sortcode)
          setValue('iban', data?.iban)
          setPhone(data?.contactPhone)
          setIsIban(!!data?.iban)

          setCanEditOrder((data?.canEditOrder === undefined || data?.canEditOrder === null) ? false : Boolean(data.canEditOrder))
          setCanSendToProduction((data?.canSendToProduction === undefined || data?.canSendToProduction === null) ? false : Boolean(data.canSendToProduction))
          setCanSendMarketingMessages((data?.canSendMarketingMessages === undefined || data?.canSendMarketingMessages === null) ? false : Boolean(data.canSendMarketingMessages))
          setTierDiscount(data?.tierDiscount)
          setRbDiscount((data?.rbDiscount === undefined || data?.rbDiscount === null) ? 5 : data?.rbDiscount)
          setRbAvailability((data?.rbAvailability === undefined || data?.rbAvailability === null) ? true : Boolean(data?.rbAvailability))
          setQcAvailability((data?.qcAvailability === undefined || data?.qcAvailability === null) ? true : Boolean(data?.qcAvailability))
          setIpAvailability((data?.ipAvailability === undefined || data?.ipAvailability === null) ? false : Boolean(data?.ipAvailability))
          setIndividualProducts(data?.individualProducts || [])
          let userCurrency = ''
          if (
            ['GB', 'UK', 'United Kingdom'].includes(data?.shippingAddress?.country) ||
            ['GB', 'UK', 'United Kingdom'].includes(data?.country)
          ) {
            userCurrency = 'UK'
          } else {
            userCurrency = 'HU'
          }
          setCurrency(userCurrency)
        }
      })
      .catch(err => {
        console.log('err', err)
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const approve = async () => {
    setApproveIsLoading(true)

    let ref
    const needRef = !user?.ref && !user?.inviteCode
    
    if(needRef) {
      ref = await api.get(`s/new_refncode`)
    }

    await api.post('updateUser', {
      id: expertId || id,
      approved: 1,
      tierDiscount: tierDiscount || 5,
      ...(needRef && {
        inviteCode: ref.data.code,
        ref: ref.data.ref,
      })
    }).then(async res => {
      if (user?.agreementID && !user.approved) {
        api.get(`s/agreement/pdf/${user.agreementID}`)
      }

      // axios.get(`${CRM_URL}/s/crmadd/31000024062/` + expertId || id)
      if(ref?.data?.code) {
        api.get(`sh/create_coupon?code=${ref.data.code}`)
      }

      setUser(prev => ({ ...prev, approved: 1 }))
    }).catch(err => {
      console.log('err', err)
    }).finally(() => {
      setApproveIsLoading(false)
    })
  }

  const disapprove = async () => {
    setApproveIsLoading(true)

    await api.post('updateUser', {id: expertId || id, approved: 0}).then(async res => {
      // axios.get(`${CRM_URL}/s/crmadd/31000024063/` + expertId || id)

      setUser(prev => ({ ...prev, approved: 0 }))
    }).catch(err => {
      console.log('err', err)
    }).finally(() => {
      setApproveIsLoading(false)
    })
  }

  const toggleApprove = () => {
    if (user?.approved) {
      disapprove()
    } else {
      approve()
    }
  }

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  useEffect(() => {
    api.get('getCountries').then(res => {
      setCountries(res.data)

      if (expertId) {
        console.log(1);
        getUser(expertId)
      } else if (id !== originalId) {
        console.log(2);
        getUser(id)
      } else {
        console.log(3);
        onAuthStateChanged(getAuth(), async (usr) => {
          if (usr) {
            getUser(usr.uid)
          } else {
            history.push("/auth/signup");
          }
        })
      }
    })
  }, [])

  return (
    <Box
      w={'100%'}
      h={'100%'}
      minH="100vh"
      background={'#FFFFFF'}
      p="34px"
    >
      <Box maxW={'920px'} margin={'0 auto'}>
        <Center mb="56px">
          <Link to="/admin/dashboard">
            <img
              src={LibiLogo}
              width="135"
              alt="Logo"
            />
          </Link>
        </Center>
        <Flex
          alignItems={"center"}
          justifyContent="space-between"
        >
          <div>
            <h2 className="title">{t('portal_profile_top_title')}</h2>
            <p className="descr">{t('portal_profile_top_description')}</p>
          </div>
          {user?.inviteCode &&
            <Flex
              alignItems={"center"}
              gap="10px"
              cursor={"pointer"}
              color="#92001A"
            >
              <Flex h="46px" alignItems={"center"} className="custom-link products-table-title">
                {t('portal_profile_my_invitation_code_link')?.replace('[[code]]', user.inviteCode)}
                <span>{user.inviteCode}</span>
              </Flex>
              <div onClick={copyHandler}><CopyIcon /></div>
            </Flex>
          }
        </Flex>

        <hr className="hr" style={{ margin: '30px 0' }} />

        {isLoading && (
          <Flex textAlign={"center"}>
            <Spinner color="red.700" size="xl" />
          </Flex>
        )}
        {!isLoading && isError && <p className="error">{t('portal_error_message')}</p>}
        {!isLoading && !isError ? (
          <>
            <Flex
              alignItems={"center"}
              justifyContent="space-between"
              mb="30px"
            >
              <Flex
                alignItems={"center"}
                gap="35px"
              >
                <label className="upload-avatar">
                  {imgSrc || user?.picture
                    ? (
                      <img src={imgSrc || user?.picture} alt="Avatar" />
                    ) : (
                      <Flex
                        w="72px"
                        h="72px"
                        alignItems={"center"}
                        justifyContent="center"
                        border={'2px solid #92001A'}
                        borderRadius={"50%"}
                        color="#92001A"
                        fontSize="24px"
                      >
                        <BsImage />
                      </Flex>
                    )
                  }
                  <input
                    type="file"
                    onChange={handleChange}
                    accept=".png, .jpg, .jpeg"
                  />
                  <span
                    className="upload-avatar-icon"
                    style={{ transform: imgSrc || user?.picture ? '' : 'rotate(45deg)' }}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#92001A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 9L9 15" stroke="#92001A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9 9L15 15" stroke="#92001A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </label>

                <div>
                  <b>{user?.firstName} {user?.lastName}</b>
                  <Text mt="9px">{user?.email}</Text>
                </div>
              </Flex>
              {id !== originalId || expertId ? (
                <button
                  type="button"
                  disabled={approveIsLoading}
                  className="custom-btn"
                  onClick={toggleApprove}
                >
                  {(user.approved === 0) && t('portal_experts_view_discount_approve')}
                  {(user.approved === 1) && t('portal_experts_view_discount_disapprove')}
                  {(user.approved === undefined) && t('portal_experts_view_discount_not_approved')}
                </button>
              ) : (
                <Link to="/auth/change-email" className="custom-link">{t('portal_profile_change_email_link')}</Link>
              )}
            </Flex>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex gap="38px" mb="24px" flexDirection={{ lg: 'row', sm: 'column' }}>
                <FormControl isInvalid={errors?.companyName}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_company_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("companyName")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_company_title')?.replace(':', '')}
                  />
                  {errors?.companyName && <FormErrorMessage>{errors.companyName?.message}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors?.contactFirstName}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_contact_first_name_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("contactFirstName")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_contact_first_name_title')?.replace(':', '')}
                  />
                  {errors?.contactFirstName && <FormErrorMessage>{errors.contactFirstName?.message}</FormErrorMessage>}
                </FormControl>
              </Flex>
              <Flex gap="38px" my="24px" flexDirection={{ lg: 'row', sm: 'column' }}>
                <FormControl isInvalid={errors?.address}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_address_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("address")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_address_title')?.replace(':', '')}
                  />
                  {errors?.address && <FormErrorMessage>{errors.address?.message}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors?.contactLastName}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_contact_last_name_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("contactLastName")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_contact_last_name_title')?.replace(':', '')}
                  />
                  {errors?.contactLastName && <FormErrorMessage>{errors.contactLastName?.message}</FormErrorMessage>}
                </FormControl>
              </Flex>
              <Flex gap="38px" my="24px" flexDirection={{ lg: 'row', sm: 'column' }}>
                <FormControl isInvalid={errors?.city}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_city_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("city")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_city_title')?.replace(':', '')}
                  />
                  {errors?.city && <FormErrorMessage>{errors.city?.message}</FormErrorMessage>}
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_phone_title')?.replace(':', '*')}</FormLabel>
                  <PhoneInput
                    country={'gb'}
                    countryCodeEditable={false}
                    value={phone}
                    onChange={value => setPhone(value)}
                    inputStyle={{
                      background: 'transparent',
                      color: '#3D2541',
                      height: '44px',
                      width: '100%',
                      borderRadius: '0.375rem',
                      borderColor: 'rgba(61, 37, 65, 0.4)'
                    }}
                    dropdownClass="phone-input-dropdown"
                    buttonClass="phone-input-wrapper"
                  />
                </FormControl>
              </Flex>
              <Flex gap="38px" mb="24px" flexDirection={{ lg: 'row', sm: 'column' }}>
                <FormControl isInvalid={errors?.country}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_country_title')?.replace(':', '*')}</FormLabel>
                  <Select {...register("country")} borderColor={'rgba(61, 37, 65, 0.4)'} h="44px">
                    <option value="">{t('portal_profile_country_title')?.replace(':', '')}</option>
                    {countries.map(i => (
                      <option key={i.code} value={i.code}>{i.name}</option>
                    ))}
                  </Select>
                  {errors?.country && <FormErrorMessage>{errors.country?.message}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors?.postcode}>
                  <FormLabel fontSize={'15px'}>{t('portal_profile_postcode_title')?.replace(':', '*')}</FormLabel>
                  <Input
                    {...register("postcode")}
                    type='text'
                    borderColor={'rgba(61, 37, 65, 0.4)'}
                    height="44px"
                    placeholder={t('portal_profile_postcode_title')?.replace(':', '')}
                  />
                  {errors?.postcode && <FormErrorMessage>{errors.postcode?.message}</FormErrorMessage>}
                </FormControl>
              </Flex>

              <Flex gap="38px" flexDirection={{ lg: 'row', sm: 'column' }}>
                <Box mb="4px" textAlign={'center'}>
                  <div className='af-tabs'>
                    <div
                      className={`title af-tab ${!isIban && 'af-tab-active'}`}
                      onClick={() => setIsIban(false)}
                    >
                      UK local bank account
                    </div>
                    <div
                      className={`title af-tab ${isIban && 'af-tab-active'}`}
                      onClick={() => setIsIban(true)}
                    >
                      IBAN
                    </div>
                  </div>
                </Box>
                <Box w="100%" />
              </Flex>

              {isIban ?
                <Flex gap="38px" flexDirection={{ lg: 'row', sm: 'column' }}>
                  <FormControl isInvalid={errors?.iban} mb="20px">
                    <FormLabel>IBAN*</FormLabel>
                    <Input
                      {...register("iban")}
                      key={1}
                      type="text"
                      borderColor={'rgba(61, 37, 65, 0.4)'}
                      height="44px"
                      placeholder="Your IBAN number"
                    />
                    <Text
                      fontWeight={500}
                      fontSize={12}
                      color="#3D2541"
                      mt="7px"
                    >
                      Example: GB88 TRWI 2314 7060 7017 99
                    </Text>
                    {errors?.iban && (
                      <FormErrorMessage>
                        {errors.iban?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <Box w="100%" />
                </Flex> :
                <Flex gap="38px" flexDirection={{ lg: 'row', sm: 'column' }}>
                  <FormControl
                    isInvalid={errors?.bankAccountNumber}
                    mb="20px"
                  >
                    <FormLabel>{t('portal_profile_bank_title')?.replace(':', '*')}</FormLabel>
                    <Input
                      {...register("bankAccountNumber")}
                      key={2}
                      type="text"
                      borderColor={'rgba(61, 37, 65, 0.4)'}
                      height="44px"
                      placeholder={t('portal_profile_bank_title')?.replace(':', '')}
                    />
                    <Text
                      fontWeight={500}
                      fontSize={12}
                      color="#3D2541"
                      mt="7px"
                    >
                      Example: 60701799
                    </Text>
                    {errors?.bankAccountNumber && (
                      <FormErrorMessage>
                        {errors.bankAccountNumber?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors?.sortcode} mb="20px">
                    <FormLabel>{t('portal_profile_sortcode_title')?.replace(':', '*')}</FormLabel>
                    <Input
                      {...register("sortcode")}
                      type="text"
                      borderColor={'rgba(61, 37, 65, 0.4)'}
                      height="44px"
                      placeholder={t('portal_profile_sortcode_title')?.replace(':', '')}
                    />
                    <Text
                      fontWeight={500}
                      fontSize={12}
                      color="#3D2541"
                      mt="7px"
                    >
                      Example: 23-14-70
                    </Text>
                    {errors?.sortcode && (
                      <FormErrorMessage>
                        {errors.sortcode?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
              }

              {roleId === 2 && <>
                <hr className="hr" style={{ margin: '0 0 30px' }} />
                <label className='experts-products-label'>
                  Send marketing messages
                  <Checkbox
                    checked={canSendMarketingMessages}
                    onChange={ev => setCanSendMarketingMessages(ev.target.checked)}
                  />
                </label>
                <label className='experts-products-label'>
                  Sending to production is allowed
                  <Checkbox
                    checked={canSendToProduction}
                    onChange={ev => setCanSendToProduction(ev.target.checked)}
                  />
                </label>
                <label className='experts-products-label'>
                  {t('portal_profile_can_edit_order')}
                  <Checkbox
                    checked={canEditOrder}
                    onChange={ev => setCanEditOrder(ev.target.checked)}
                  />
                </label>
              </>}

              {id !== originalId || expertId ?
                <ExpertProducts
                  expertId={user.id}
                  rbAvailability={rbAvailability}
                  setRbAvailability={setRbAvailability}
                  qcAvailability={qcAvailability}
                  setQcAvailability={setQcAvailability}
                  ipAvailability={ipAvailability}
                  setIpAvailability={setIpAvailability}
                  tierDiscount={tierDiscount}
                  setTierDiscount={setTierDiscount}
                  rbDiscount={rbDiscount}
                  setRbDiscount={setRbDiscount}
                  individualProducts={individualProducts}
                  setIndividualProducts={setIndividualProducts}
                  currency={currency}
                />
              : null}

              {updateIsError && <Text fontSize="sm" textAlign={"right"} mb="20px" color="red.300">{t('portal_error_message')}</Text>}
              {codeOrBankError && <Text fontSize="sm" textAlign={"right"} mb="20px" color="red.300">Bank number or sort code is invalid</Text>}

              <hr className="hr" style={{ margin: '0 0 30px' }} />

              <Flex alignItems={"center"} justifyContent={"flex-end"} mt="16px" gap="32px">
                <div onClick={goBack} className='custom-link'>
                  {t('portal_profile_back_link')}
                </div>
                <button
                  type="submit"
                  disabled={uodateIsLoading}
                  className="custom-btn"
                >
                  {uodateIsLoading ? t('portal_loading') : t('portal_profile_update_button')}
                </button>
              </Flex>
            </form>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default ProfileEdit;
