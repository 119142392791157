import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  getDocs
} from "firebase/firestore";
import {
  Box,
  Flex,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setAnswerId } from "store/slices/assessmentSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import api from "centralApi";

function UserAnswers({ userId, answerId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [answers, setAnswers] = useState([])
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [questionsRu, setQuestionsRu] = useState([]);
  const [questionsEn, setQuestionsEn] = useState([]);
  const [calculated, setCalculated] = useState(false)

  const findQuestion = (array, ref) => {
    for (const item of array) {
      if (item?.ref === ref) {
        return item;
      }
      if (item?.properties.hasOwnProperty("fields")) {
        for (const subItem of item?.properties?.fields) {
          if (subItem?.ref === ref) {
            return subItem;
          }
        }
      }
    }
    return false;
  };

  const getChoiceLabel = (itemLang, ref, label) => {
    let choiceLang = itemLang?.properties?.choices?.find((x) => x?.ref === ref);
    return choiceLang ? choiceLang.label : label;
  };

  const getQuestions = async (mark) => {
    const metaRef = collection(db, "Meta");
    const querySnapshot = await getDocs(metaRef);
    querySnapshot.forEach((doc) => {
      const data = doc?.data()?.[mark + "_json"]
      if (doc?.id === "questionnaire_new") {
        data && setQuestionsEn(JSON.parse(data)?.fields);
      }
      if (doc?.id === "questionnaire_ru") {
        data && setQuestionsRu(JSON.parse(data)?.fields);
      }
    });
  };

  const renderAnswer = (lang) => {
    let answerObj = [];
    currentAnswers &&
      currentAnswers.forEach((val, key) => {
        if (val.type === "file_upload") {
          answerObj.push(
            <Box key={key} mb="8px">
              <Text
                fontFamily={'Cormorant Garamond'}
                fontSize="18px"
                fontWeight={600}
                color="#3D2541"
                mb="8px"
              >
                {`Q${++key}. `}
                {val.question[lang]}
              </Text>
              <Text
                fontSize="15px"
                color="#219653"
              >
                A.
              </Text>
              <div>
                <img alt="Test Image" src={val.answer} />
              </div>
            </Box>
          );
        } else {
          answerObj.push(
            <Box key={key} mb="8px">
              <Text
                fontFamily={'Cormorant Garamond'}
                fontSize="18px"
                fontWeight={600}
                color="#3D2541"
                mb="8px"
              >
                {`Q${++key}. `}
                {val.question[lang]}
              </Text>
              <Text
                fontSize="15px"
                color="#219653"
                whiteSpace="pre"
              >
                A. {val.answer[lang]}
              </Text>
              {/* {val?.choices?.length
                ? val.choices.map((c, i) => {
                  return (
                    <Text
                      fontSize="15px"
                      color="red.400"
                      key={i}
                    >
                      -{c[lang]}{" "}
                    </Text>
                  );
                })
                : null} */}
            </Box>
          );
        }
      });
    return answerObj;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      let {data: answersArr} = await api.get('getAnswersByUserId', {params: {userId}})

      answersArr = answersArr?.sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? -1 : 1) || []

      if (answersArr.length > 0) {
        setAnswers(answersArr)
        setSelectedAnswer(answerId ? answersArr.find(i => i.id === answerId) : answersArr[0])
      } else {
        setAnswers([])
        setSelectedAnswer({})
        setCurrentAnswers([]);
        dispatch(setAnswerId(""));
        setIsError(false);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsError(true);
      setIsLoading(false);с
    }
  }

  const calcAnswer = async (answ) => {
    dispatch(setAnswerId(answ.id));

    if (questionsRu.length === 0) {
      await getQuestions(answ.mark?.toLowerCase());
      return;
    }

    const { form_response } = answ || {};
    if (
      form_response &&
      form_response.definition &&
      form_response.answers
    ) {
      let definition = form_response.definition;
      let answers = form_response.answers;
      let answersArr = [];
      definition.fields.forEach((question, itemKey) => {
        let item = answers.find(
          (answer) =>
            answer.field.type === question.type &&
            answer.field.ref === question.ref
        );
        let itemRu = findQuestion(questionsRu, question.ref);
        let itemEn = findQuestion(questionsEn, question.ref);
        if (item && itemEn) {
          if (question.type === "short_text" || question.type === "long_text" || question.type === "text") {
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              }, // question.title
              answer: { en: item.text, ru: item.text },
              type: question.type,
            });
          } else if (question.type === "dropdown") {
            let choiceRu = getChoiceLabel(
              itemRu,
              item?.choice?.ref,
              item?.choice?.label
            );
            let choiceEn = getChoiceLabel(
              itemEn,
              item?.choice?.ref,
              item?.choice?.label
            );
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: { en: choiceEn, ru: choiceRu },
              type: question.type,
            });
          } else if (question.type === "multiple_choice") {
            if (question.allow_multiple_selections) {
              let answer;
              if (item.choices && item.choices.other) {
                answer = {
                  en: item.choices.other,
                  ru: item.choices.other,
                };
              } else {
                if (item.choices) {
                  let answerRu = [];
                  let answerEn = [];
                  let index = 0;
                  if (
                    item.choices.hasOwnProperty(
                      "refs"
                    )
                  ) {
                    for (const ref of item?.choices?.refs) {
                      answerRu.push(
                        getChoiceLabel(
                          itemRu,
                          ref,
                          item.choices.labels[
                          index
                          ]
                        )
                      );
                      answerEn.push(
                        getChoiceLabel(
                          itemEn,
                          ref,
                          item.choices.labels[
                          index
                          ]
                        )
                      );
                      index++;
                    }
                    answer = {
                      en: answerEn.join(),
                      ru: answerRu.join(),
                    };
                  } else {
                    answer = {
                      en: item.choices.labels.join(),
                      ru: item.choices.labels.join(),
                    };
                  }
                } else {
                  answer = { en: "", ru: "" };
                }
              }
              let choices = question?.choices?.length
                ? question.choices.map((e) => {
                  return {
                    en: getChoiceLabel(
                      itemEn,
                      e.ref,
                      e.label
                    ),
                    ru: getChoiceLabel(
                      itemRu,
                      e.ref,
                      e.label
                    ),
                  };
                })
                : [];
              answersArr.push({
                id: question.id,
                ref: question.ref,
                question: {
                  en: itemEn.title,
                  ru: itemRu.title,
                },
                answer: answer,
                type: question.type,
                choices: choices,
              });
            } else {
              let choiceRu = getChoiceLabel(
                itemRu,
                item?.choice?.ref,
                item?.choice?.label
              );
              let choiceEn = getChoiceLabel(
                itemEn,
                item?.choice?.ref,
                item?.choice?.label
              );
              let choices = question?.choices?.length
                ? question.choices.map((e) => {
                  return {
                    en: getChoiceLabel(
                      itemEn,
                      e.ref,
                      e.label
                    ),
                    ru: getChoiceLabel(
                      itemRu,
                      e.ref,
                      e.label
                    ),
                  };
                })
                : [];
              answersArr.push({
                id: question.id,
                ref: question.ref,
                question: {
                  en: itemEn.title,
                  ru: itemRu.title,
                },
                answer: { en: choiceEn, ru: choiceRu },
                type: question.type,
                choices: choices,
              });
            }
          } else if (question.type === "date") {
            if (item.field.ref === question.ref) {
              answersArr.push({
                id: question.id,
                ref: question.ref,
                question: {
                  en: itemEn.title,
                  ru: itemRu.title,
                },
                answer: {
                  en: item.date,
                  ru: item.date,
                },
                type: question.type,
              });
            }
          } else if (question.type === "yes_no") {
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: {
                en:
                  item.boolean === true
                    ? "Yes"
                    : "No",
                ru:
                  item.boolean === true
                    ? "Да"
                    : "Нет",
              },
              type: question.type,
            });
          } else if (question.type === "file_upload") {
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: item.file_url,
              type: question.type,
            });
          } else if (question.type === "rating") {
            const stars = Array.from({length: item.number}).map(() => '⭐️')
            
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: {en: stars, ru: stars},
              type: question.type,
            });
          } else if (question.type === "phone_number") {
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: {en: item.phone_number, ru: item.phone_number},
              type: question.type,
            });
          } else if (question.type === "number") {
            answersArr.push({
              id: question.id,
              ref: question.ref,
              question: {
                en: itemEn.title,
                ru: itemRu.title,
              },
              answer: { en: item.number, ru: item.number },
              type: question.type,
            });
          }
        }
      });
      setCurrentAnswers(answersArr);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData();
  }, [userId]);

  useEffect(() => {
    calcAnswer(selectedAnswer)
  }, [selectedAnswer, questionsRu, questionsEn])

  useEffect(() => {
    if(selectedAnswer?.mark) {
      setCalculated(true)
      getQuestions(selectedAnswer.mark).then(() => setCalculated(false))
    }
  }, [selectedAnswer.mark])

  return (
    <>
      {isLoading && (
        <Flex justifyContent="center">
          <Spinner color="red.700" size="xl" />
        </Flex>
      )}
      {!isLoading && isError && (
        <Text mx="auto">{t('portal_error_message')}</Text>
      )}
      {!isLoading && !isError &&
        <>
          {(answers.length > 0 && !answerId) &&
            <div>
              <Text
                fontWeight={600}
                fontSize={15}
                mb={'8px'}
              >
                {t('portal_orders_new_answers_description')}
              </Text>
              <Select
                w="440px"
                h="44px"
                borderColor={'#3D2541'}
                onChange={(ev) => setSelectedAnswer(answers.find(i => i.id === ev.target.value))}
              >
                {answers?.map(i => (
                  <option
                    key={i.id}
                    value={i.id}
                  >
                    {moment(i.time).format("DD/MM/YYYY")}
                  </option>
                ))}
              </Select>
            </div>
          }
          {calculated
            ? (
              <Flex justifyContent="center" mt="20px">
                <Spinner color="red.700" size="xl" />
              </Flex>
            )
            :
            currentAnswers.length > 0 ?
              <div className={answerId ? "singleAnswer" : "answers"}>
                {renderAnswer("en")}
                {/* {renderAnswer("ru")} */}
              </div>
              : <Text mx="auto" mt="20px">{t('portal_orders_new_no_answers')}</Text>
          }

          {/* <Tabs colorScheme="red">
            <TabList>
              <Tab _css={{ outline: "none" }}>EN</Tab>
              <Tab _css={{ outline: "none" }}>RU</Tab>
            </TabList>

            <TabPanels>
              <TabPanel maxH="888px" overflow="auto">
                {renderAnswer("en")}
              </TabPanel>
              <TabPanel maxH="888px" overflow="auto">
                {renderAnswer("ru")}
              </TabPanel>
            </TabPanels>
          </Tabs> */}
        </>
      }
    </>
  );
}

export default UserAnswers;
