import React, { useState, useCallback } from 'react'
import { Center, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text } from '@chakra-ui/react'
import bg from 'assets/img/change-email.jpeg'
import LibiLogo from "assets/img/libiLogoBlack.png";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link, useHistory } from 'react-router-dom';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updateEmail, sendEmailVerification } from "firebase/auth";
import api from "centralApi";

const requireString = yup.string().required('This field is required')

const schema = yup.object({
  oldEmail: requireString.email('Invalid email'),
  newEmail: requireString.email('Invalid email'),
  password: requireString,
});

const ChangeEmail = () => {
  const { t } = useTranslation();
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')

  const { register, handleSubmit, formState: { errors }, setValue} = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    setIsLoading(true)

    const credential = EmailAuthProvider.credential(
      data.oldEmail, data.password
    );
    
    const auth = getAuth();
    reauthenticateWithCredential(auth.currentUser, credential)
      .then((usercred) => {
        console.log('success')
        updateEmail(auth.currentUser, data.newEmail).then(() => {
          api.post('updateUser', {
            id: auth.currentUser.uid,
            email: data.newEmail
          }).then(() => {
            sendEmailVerification(auth.currentUser).then(() => {
              history.push('/auth/signin')
            })
          })
        }).catch((error) => {
          console.log('error', error)
          setIsError(t('portal_error_message'))
          setIsLoading(false)
        });
      })
      .catch((error) => {
        setIsError('Invalid email or password')
        setIsLoading(false)
      });
  }

  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  }, [history]);

  return (
    <Flex>
      <Flex
        minH="100vh"
        width={{ md: '50%', sm: '100%' }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Center mb="8px">
          <Link to="/admin/dashboard">
            <img width={"135px"} src={LibiLogo} alt="Logo" />
          </Link>
        </Center>
        <h2 className="title">{t('portal_change_email_top_title','Change email address')}</h2>
        <Text fontSize={15} fontWeight={500} mt="6px" mb="24px">{t('portal_change_email_top_description','Please, change email address')}</Text>

        <form style={{width: '100%', maxWidth: '460px'}} onSubmit={handleSubmit(onSubmit)}>
          <Flex gap="20px" px="10px" flexDirection="column">
            <FormControl isInvalid={errors?.oldEmail}>
              <FormLabel fontSize={'15px'}>{t('portal_change_email_old_email_title','Old Email *')}</FormLabel>
              <Input
                {...register("oldEmail")}
                type='text'
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder={t('portal_change_email_old_email_placeholder',"Your email address")}
                autoComplete="new-password"
              />
              {errors?.oldEmail && <FormErrorMessage>{errors.oldEmail?.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors?.newEmail}>
              <FormLabel fontSize={'15px'}>{t('portal_change_email_new_email_title','New Email *')}</FormLabel>
              <Input
                {...register("newEmail")}
                type='text'
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder={t('portal_change_email_new_email_placeholder',"New email address")}
                autoComplete="new-password"
              />
              {errors?.newEmail && <FormErrorMessage>{errors.newEmail?.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors?.password}>
              <FormLabel fontSize={'15px'}>{t('portal_change_email_password_title','Password *')}</FormLabel>
              <Input
                {...register("password")}
                type='password'
                borderColor={'rgba(61, 37, 65, 0.4)'}
                height="44px"
                placeholder={t('portal_change_email_password_placeholder',"Your password")}
                autoComplete="new-password"
              />
              {errors?.password && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
            </FormControl>

            {isError && <Text color="red.400">{isError}</Text>}
            <Flex alignItems={"center"} justifyContent={"space-between"} mt="16px" gap="20px">
              <div className='custom-link' onClick={goBack}>
                {t('portal_profile_back_link')}
              </div>
              <button
                type="submit"
                className='custom-btn'
                disabled={isLoading}
              >
                {isLoading ? t('portal_loading') : t('portal_change_email_submit_button','Save')}
              </button>
            </Flex>
          </Flex>
        </form>
      </Flex>
      <Flex
        minH="100vh"
        width={'50%'}
        display={{ md: 'flex', sm: 'none' }}
        bg="#fff"
        alignItems={'center'}
      >
        <img src={bg} alt="Image" width={'100%'} />
      </Flex>
    </Flex>
  )
}

export default ChangeEmail